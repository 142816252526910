body,
html {
  font-family: 'Encode Sans Expanded', sans-serif;
  color: #2C363B;
  font-size: small;
}




