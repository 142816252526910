// Here you can add other styles
.bg-sidebar {
    background-color: #2C363B;
    font-weight: bold;
    color: #C9D0E0
}

.bg-sidebar-header-left {
    background-color: #C9D0E0;
}

.bg-sidebar-row {
    color: #C9D0E0;
}

.bg-sidebar-group-item {
    color: #C9D0E0;
    font-weight: 'lighter';
}

.bg-sidebar-row:hover {
    background-color: #00965E;
}

.bg-header {
    background-color: #DEE5F5;
}

.font-bold {
    font-weight: bold;
}

.card-full-size {
    font-size: 40px;
}

.card-mid-size {
    font-size: 20px
}

.card-small-size {
    font-size: 10px
}

.text-green {
    color: #00965E;
}

.text-red {
    color: red;
}

.text-blue {
    color: #0070C0;
}

.text-primary-color {
    color: #2C363B;
}

.chart-pie {
    height: 100px !important;
}

.card-dashboard {
    border-style: 5px solid;
}

.add_header {
    text-align: 'center';
    font-size: '2.5em';
}

.dropdown-row {
    color: 'red';
}

.dropdown-row :hover {
    background-color: #2C363B;
}


.default-border {
    border: 2px solid;
}

.model-blue-button {
    border-color: #2F5597;
    background-color: #2F5597;
}

.model-blue-button:hover {
    background-color: #2F5597;  
    opacity: 25;
}

.blue-button {
    color: #2F5597;
    border-color: #2F5597;
    border: 2px solid;
    font-weight: bold;
    
}



.blue-button:hover {
    background-color: #2F5597;
    color: white;
    border-color: #2F5597;
    
}

.no-border-input {
    outline: 'none';
    border: 0;
}


//loading
.loader {
    width: 48px;
    height: 48px;
    display: block;
    margin:15px auto;
    position: relative;
    color: #414A4E;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: #00965E;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }
  .loader::before {
    background-color: #414A4E;
    transform: scale(0.5) translate(-48px, -48px);
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes animloader {
      50% {
        transform: scale(1) translate(-50%, -50%);
  }
  }
      

